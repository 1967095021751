<template>
  <form @submit.prevent="createHomework()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.class_id.$error }">
            <ion-label position="floating">Class</ion-label>
            <ion-select
              v-model="class_id"
              @ionChange="fetchSubject($event.target.value)"
            >
              <ion-select-option disabled selected>Select</ion-select-option>
              <ion-select-option
                v-for="classe of classes"
                :key="classe.section_standard_id"
                :value="classe.section_standard_id"
              >
                {{ classe.standard.name }} - {{ classe.section.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.subject_id.$error }">
            <ion-label position="floating">Subject</ion-label>
            <ion-select
              v-if="subjects.length > 0"
              v-model="subject_id"
              @ionChange="fetchChapter($event.target.value)"
            >
              <ion-select-option
                v-for="subject of subjects"
                :key="subject.id"
                :value="subject.id"
              >
                {{ subject.name }}
              </ion-select-option>
            </ion-select>
            <ion-select v-else disabled>
              <ion-select-option> Select </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.chapter_id.$error }">
            <ion-label position="floating">Chapter</ion-label>
            <ion-select v-if="subjects.length > 0 && chapters.length > 0" v-model="chapter_id">
              <ion-select-option
                v-for="chapter of chapters"
                :key="chapter.id"
                :value="chapter.id"
              >
                {{ chapter.name }}
              </ion-select-option>
            </ion-select>
            <ion-select v-else disabled>
              <ion-select-option> Select </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.homework_from_date.$error }">
            <ion-label position="floating">Start date</ion-label>
            <ion-datetime
              type="text"
              v-model="homework_from_date"
            ></ion-datetime>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.homework_to_date.$error }">
            <ion-label position="floating">Completion date</ion-label>
            <ion-datetime type="text" v-model="homework_to_date"></ion-datetime>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.description.$error }">
            <ion-label position="floating">Description</ion-label>
            <ion-textarea
              type="text"
              v-model="description"
              rows="2"
            ></ion-textarea>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonIcon,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonDatetime,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, maxLength, integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

export default {
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonIcon,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonDatetime,
    IonText,
  },
  data() {
    return {
      class_id: null,
      subject_id: null,
      chapter_id: null,
      description: null,
      homework_from_date: null,
      homework_to_date: null,

      validationError: null,

      alertCircleOutline,
      alertCircleSharp,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    classes() {
      return this.$store.getters["class/allResources"];
    },

    subjects() {
      return this.$store.getters[
        "teacherSubjectBySectionStandard/allResources"
      ];
    },

    chapters() {
      return this.$store.getters["chapter/allResources"];
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      class_id: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      subject_id: {
        required: helpers.withMessage("is required", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
      chapter_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },

      homework_from_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      homework_to_date: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
        // Custom Validator
        minValue: helpers.withMessage(
          "cannot be less than from date",
          function minValue(val) {
            return new Date(val) >= new Date(this.homework_from_date);
          }
        ),
      },

      description: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async createHomework() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        section_standard_id: this.class_id,
        subject_id: this.subject_id,
        chapter_id: this.chapter_id,
        homework_from_date: moment(this.homework_from_date).format("YYYY-MM-DD"),
        homework_to_date: moment(this.homework_to_date).format("YYYY-MM-DD"),
        description: this.description,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("homework/createResource", data);
        spinner.dismiss();

        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async fetchSubject(class_id) {
      this.subject_id = null;
      this.chapter_id = null;
      class_id ? await this.$store.dispatch("teacherSubjectBySectionStandard/allResources", class_id) : null;
    },

    async fetchChapter(subject_id) {
      this.chapter_id = null;
      subject_id ? await this.$store.dispatch("chapter/allResources", subject_id) : null;
    },

    async fetchClasses() {
      await this.$store.dispatch("class/allResources");
    },
  },

  created() {
    this.fetchClasses();
  },
};
</script>