<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-card>
        <ion-item lines="none" class="ion-no-padding">
          <ion-card-header>
            <ion-card-title
              >Class {{ item.section_standard.standard.name }}-{{
                item.section_standard.section.name
              }}</ion-card-title
            >
          </ion-card-header>
          <ion-button
            @click="
              confirmDelete({
                id: item.id,
              })
            "
            fill="clear"
            slot="end"
          >
            <ion-icon
              slot="icon-only"
              :ios="trashOutline"
              :md="trashSharp"
              size="small"
              style="color: #eb445a"
            ></ion-icon>
          </ion-button>
        </ion-item>
        <ion-card-header style="padding-top: 0">
          <ion-card-subtitle>
            <ion-text color="primary">
              {{ item.subject.name }} -
              {{ item.chapter ? item.chapter.name : "N/A" }}
            </ion-text></ion-card-subtitle
          >
        </ion-card-header>
        <ion-card-content>
          {{ item.description }}
          <br />
          <br />
          Created: {{ new Date(item.created_at).toDateString() }}
          <br />
          {{ new Date(item.homework_to_date).toDateString() }} to
          {{ new Date(item.homework_to_date).toDateString() }}
        </ion-card-content>
        <ion-item>
          <!-- <ion-button
              fill="solid"
              expand="full"
              :router-link="{
                name: 'homework.progression',
                params: { homework: item.id, description: description(item) },
              }"
              >Progression</ion-button
            > -->
          <ion-button
            slot="end"
            @click="setResourceToUpdate(item.id)"
            fill="clear"
          >
            <ion-icon
              slot="icon-only"
              :ios="createOutline"
              :md="createSharp"
              size="small"
              style="color: grey"
            ></ion-icon>
          </ion-button>
        </ion-item>
      </ion-card>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No homework found</p>
    </ion-list-header>
  </ion-list>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal title="Update chapter" @closeModal="setUpdateModalState(false)">
      <update-homework
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-homework>
    </base-modal>
  </ion-modal>
</template>
<script>
import BaseModal from "../../Base/BaseModal.vue";
import UpdateHomework from "../Homework/UpdateHomework.vue";

import {
  IonList,
  IonListHeader,
  IonItem,
  IonModal,
  IonButton,
  IonIcon,
  alertController,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonText,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
  createOutline,
  createSharp,
} from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonModal,
    IonButton,
    IonIcon,
    BaseModal,
    UpdateHomework,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonText,
  },
  data() {
    return {
      createOutline,
      createSharp,
      trashOutline,
      trashSharp,

      updateModalState: false,

      resourceToUpdate: {
        id: null,
        name: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      const header = `Delete homework: ${resource.name} ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(resourceId) {
      try {
        const payLoad = {
          resourceId: resourceId,
        };

        await this.$store.dispatch("homework/deleteResource", payLoad);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },
  },
};
</script>
